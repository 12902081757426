import { selectGroupEventMasterclassName } from 'features/reduxStore/groupEventsName';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DashboardFilledButton } from 'styling/buttons';
import './styling/requests.scss';

type Props = { value: number };

const MasterclassEmptyPage = ({ value }: Props) => {
  const navigate = useNavigate();
  const masterclassName =
    useSelector(selectGroupEventMasterclassName) || 'Masterclass';
    const pluralMasterclassName =
    useSelector(selectGroupEventMasterclassName) || 'Masterclasses';
  return (
    <div className="masterclass-empty-page-container">
      <img
        className="not-found-image"
        src={'/group-conversation.png'}
        alt={'not-found'}
      />
      {value === 1 ? (
        <div className="masterclass-not-found-text">
          We record all of our {pluralMasterclassName} so that you can catch up on the
          replay whenever you need!
        </div>
      ) : (
        <div className="masterclass-not-found-text">
          {' '}
          You are not currently registered for any events
        </div>
      )}
      {value === 1 ? (
        <div className="masterclass-not-found-text">
          {' '}
          When you register for a {masterclassName} we'll share the replay here with
          you.
        </div>
      ) : null}
      <DashboardFilledButton
        sx={{ width: '250px', marginTop: '20px' }}
        variant="contained"
        onClick={() => navigate('/dashboard/recommendations')}
      >
        Check out our {pluralMasterclassName}
      </DashboardFilledButton>
    </div>
  );
};

export default MasterclassEmptyPage;
