import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ImageUploader from 'react-images-upload';
import apiInstance from '../../../api/config';
import 'react-toastify/dist/ReactToastify.css';
import { VolunteerProfile } from '../types';
import '../style/profile.css';
import '../style/index.css';
import {
  CustomProfileCancelButton,
  CustomProfileSaveButton,
} from 'styling/buttons';

import EditInput from './EditInput';
import useGetIndustries from '../../../apiHooks/useGetIndustries';
import { genderMap } from '../../../constants';

type Props = {
  data: VolunteerProfile;
};

let updatedProfile: VolunteerProfile = {};

const Profile = ({ data }: Props) => {
  const [organisation, setorganisation] = useState(data);
  const [disableSave, setDisableSave] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(
    organisation.profile_picture
  );
  const [disabilityInfo, setDisabilityInfo] = useState<string>(
    organisation?.disability_info||''
  );
  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    organisation.disabilities || []
  );
  const { industries: industryList } = useGetIndustries();
  var stageOfBusiness = null;
  switch (organisation.stage_of_business) {
    case 'pre_start':
      stageOfBusiness = 'Pre-start (working on an idea)';
      break;
    case 'starting_out':
      stageOfBusiness = 'Starting out (launched in the last 18 months)';
      break;
    case 'growing':
      stageOfBusiness = 'Growing (trading for more than 18 months)';
      break;
    default:
      stageOfBusiness = null;
  }

  var numberOfEmployees = null;
  switch (organisation.number_of_employees) {
    case 'me':
      numberOfEmployees = 'Just me';
      break;
    case '2_5':
      numberOfEmployees = '2-5';
      break;
    case '6_9':
      numberOfEmployees = '6-9';
      break;
    case '10_49':
      numberOfEmployees = '10-49';
      break;
    case '50_249':
      numberOfEmployees = '50-249';
      break;
    case 'other':
      numberOfEmployees = 'Other';
      break;
    default:
      numberOfEmployees = null;
  }

  var organisationType = null;
  switch (organisation.org_type) {
    case 'business':
      organisationType = 'Business';
      break;
    case 'charity':
      organisationType = 'Charity';
      break;
    case 'social_enterprise':
      organisationType = 'Social Enterprise';
      break;
    case 'educational_institute':
      organisationType = 'School / College / University';
      break;
    case 'freelancer':
      organisationType = 'Freelancer';
      break;
    case 'student':
      organisationType = 'Student';
      break;
    case 'health_service':
      organisationType = 'Health Services';
      break;
    case 'unemployed_furloughed':
      organisationType = 'Unemployed / Furloughed';
      break;

    default:
      organisationType = null;
  }

  useEffect(() => {
    const getAdditionalData = async () => {
      let newData = {
        ethnicityName: '',
        countryName: '',
        sectorName: '',
      };
      let sector = industryList.filter(
        (industry: { id: number | undefined }) =>
          industry.id === organisation.industry
      )[0];
      let sectorName = null;
      if (sector) sectorName = sector.name;
      if (sectorName) newData.sectorName = sectorName;
      try {
        let ethnicity = await apiInstance
          .get(`api/ethnicity/ethnicity/?limit=1000`, {})
          .then((response) => {
            let ethnicity = response.data.results.filter(
              (ethnicity: { id: number | undefined }) =>
                ethnicity.id === organisation.ethnicity
            )[0];
            return ethnicity;
          });
        let ethnicityName = null;
        if (ethnicity) ethnicityName = ethnicity.name;
        if (ethnicityName) newData.ethnicityName = ethnicityName;
      } catch (e) {
        console.error(e);
      }
      try {
        let country = await apiInstance
          .get(`api/location/countries/?limit=1000`, {})
          .then((response) => {
            let country = response.data.results.filter(
              (country: { code: string | undefined }) =>
                country.code === organisation.country
            )[0];
            return country;
          });
        let countryName = null;
        if (country) countryName = country.name;
        if (countryName) newData.countryName = countryName;
        setorganisation({ ...data, ...newData });
      } catch (e) {
        console.error(e);
      }
    };
    getAdditionalData();
  }, [
    organisation.last_name,
    data,
    organisation.country,
    organisation.employment_history,
    organisation.ethnicity,
    industryList,
    organisation.industry,
  ]);

  const handleSaveClick = async (updatedProfile: VolunteerProfile) => {
    let { summary, gender, ...organisationProfile } = updatedProfile;

    organisationProfile = {
      ...organisationProfile,
      disabilities: selectedOptions,
      disability_info: disabilityInfo,
    };

    if (
      organisationProfile.website !== undefined &&
      organisationProfile.website.startsWith('www')
    ) {
      organisationProfile.website = 'http://' + organisationProfile.website;
    }
    try {
      await apiInstance.patch(`api/location/locations/me/`, updatedProfile);
    } catch (e) {
      console.error(e);
    }
    try {
      await apiInstance.patch(
        `api/organisation/organisations/me/`,
        organisationProfile
      );
    } catch (e) {
      console.error(e);
    }
    try {
      await apiInstance.patch(`api/core/users/me/`, updatedProfile);
    } catch (e) {
      console.error(e);
    }

    window.location.reload();
  };

  const setValue = (value: any, input: any) => {
    if (
      input === 'website' &&
      value.startsWith('http') === false &&
      value.startsWith('www') === false
    )
      setDisableSave(true);
    else if (
      input === 'website' &&
      (value.startsWith('http') === true || value.startsWith('www') === true)
    )
      setDisableSave(false);
    if (input === 'disability') {
      setSelectedOptions((prevOptions) =>
        prevOptions.includes(value)
          ? prevOptions.filter((o) => o !== value)
          : [...prevOptions, value]
      );
    }

    updatedProfile = Object.assign(updatedProfile, { [`${input}`]: value });
  };
  const setDisabilityList = (value: number, input: string, key: string) => {
    if (value === -1) setSelectedOptions([]);
    else
      setSelectedOptions((prevOptions) =>
        prevOptions.includes(value)
          ? prevOptions.filter((o) => o !== value)
          : [...prevOptions, value]
      );
    if (key) setDisabilityInfo(key);
  };

  const updateProfilePhoto = async (files: (string | Blob)[]) => {
    const formData = new FormData();
    formData.append('profile_picture', files[0]);
    try {
      const profile = await apiInstance.patch(`api/core/users/me/`, formData);
      setProfilePhoto(profile.data.profile_picture);
    } catch (e) {
      console.error(e);
    }
  };

  const onDrop = (files: any) => {
    updateProfilePhoto(files);
  };

  var job = null;
  if (
    organisation.current_role !== undefined &&
    organisation.current_role !== null &&
    organisation.current_role.length > 0
  )
    job = organisation.current_role + ' at ' + organisation.name;

  const shouldShowField = () => {
    const showByOrgType = ['business', 'social_enterprise', 'charity'];

    if (
      organisation &&
      organisation?.org_type &&
      !showByOrgType.includes(organisation?.org_type)
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className="profile_form">
      <div className="profile_summary__container">
        <div className="profile-picture__container">
          {profilePhoto !== null ? (
            <div
              className="profile-picture"
              style={{ backgroundImage: `url(${profilePhoto})` }}
            />
          ) : (
            <div
              className="profile-picture"
              style={{ backgroundImage: `url('/avatar.png')` }}
            />
          )}
          <ImageUploader
            style={{ backgroundColor: 'transparent' }}
            withIcon={false}
            withPreview={false}
            buttonText="Change Profile Photo"
            label=""
            singleImage={true}
            onChange={onDrop}
            imgExtension={[
              '.jpg',
              '.gif',
              '.png',
              '.gif',
              '.svg',
              '.peg',
              '.jpeg',
            ]}
            maxFileSize={2248576}
            fileSizeError="File size is too big, please keep it below 2 MB"
          />
        </div>
        <div className="summary-text">
          <div className="volunteer-name">
            {organisation.first_name} {organisation.last_name}
          </div>
          <div className="volunteer-job">
            {job !== null ? job : organisation.name}
          </div>
          <div className="volunteer-join-date">
            Signed up on {moment(organisation.created).format('MMMM Do, YYYY')}
          </div>
        </div>
      </div>
      <EditInput
        inputField="name"
        name={'Name'}
        input={organisation.first_name + ' ' + organisation.last_name}
        value={{
          first_name: organisation.first_name,
          last_name: organisation.last_name,
        }}
        setValue={setValue}
      />

      <EditInput
        inputField="gender"
        name={'Gender Identity'}
        input={organisation.gender ? genderMap[organisation.gender] : null}
        value={organisation.gender}
        setValue={setValue}
      />

      <EditInput
        inputField="ethnicity"
        name={'Ethnicity'}
        input={organisation.ethnicityName}
        value={organisation.ethnicity}
        setValue={setValue}
      />
      <EditInput
        inputField="disability"
        name={'Disability'}
        input={
          organisation?.disabilities && organisation.disabilities.length > 0
            ? 'Yes'
            : 'No'
        }
        value={{disabilities:organisation?.disabilities, disability_info:organisation.disability_info}}
        setValue={setDisabilityList}
      />
      <EditInput
        inputField="location"
        name={'Location'}
        input={organisation.countryName + ', ' + organisation.postcode}
        value={{
          country: organisation.country,
          postcode: organisation.postcode,
        }}
        setValue={setValue}
      />

      <EditInput
        inputField="timezone"
        name={'Timezone'}
        input={organisation.timezone}
        value={organisation.timezone}
        setValue={setValue}
      />

      <EditInput
        inputField="companyRole"
        name={'Company and Role'}
        input={job !== null ? job : organisation.name}
        value={{
          role: organisation.current_role,
          company: organisation.name,
          partner: organisation.partner,
        }}
        setValue={setValue}
      />

      <EditInput
        inputField="organisationType"
        name={'Organisation Type'}
        input={organisationType}
        value={organisation.org_type}
        setValue={setValue}
      />

      {shouldShowField() && (
        <EditInput
          inputField="numberEmployeer"
          name={'Number of Employees '}
          input={numberOfEmployees}
          value={organisation.number_of_employees}
          setValue={setValue}
        />
      )}

      {shouldShowField() && (
        <EditInput
          inputField="businessStage"
          name={'Stage of Business'}
          input={stageOfBusiness}
          value={organisation.stage_of_business}
          setValue={setValue}
        />
      )}

      <EditInput
        inputField="companyWebsite"
        name={'Website'}
        input={organisation.website}
        value={organisation.website}
        setValue={setValue}
      />

      <EditInput
        inputField="introduction"
        name={'Your introduction'}
        input={
          organisation.summary !== undefined
            ? `${organisation.summary}`
            : 'No introduction available'
        }
        value={organisation.summary}
        setValue={setValue}
      />

      <EditInput
        inputField="sector"
        name={'Industry'}
        input={organisation.sectorName}
        value={organisation.industry}
        setValue={setValue}
      />

      {shouldShowField() && (
        <EditInput
          inputField="businessHealth"
          name={'Business Health'}
          input={
            organisation.business_health
              ? `${organisation.business_health}/5`
              : null
          }
          value={organisation.business_health}
          setValue={setValue}
        />
      )}

      <div className="update-profile-buttons">
        <CustomProfileSaveButton
          variant="contained"
          disabled={disableSave}
          onClick={() => handleSaveClick(updatedProfile)}
        >
          Save
        </CustomProfileSaveButton>

        <CustomProfileCancelButton
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Cancel
        </CustomProfileCancelButton>
      </div>
    </div>
  );
};

export default Profile;
