import React, { useEffect, useState, useRef } from 'react';

import { GroupEvent } from '../../types';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import { displayTime, getMomentWithTimezone } from '../../utils/time';
import { getUrlParam } from '../../utils/url';
import { toTitleCase } from '../../utils/text';
import './add-group-event-to-calendar-button.scss';
import { defaultDateFormat, defaultTimeFormat } from '../../constants';
import { DashboardEmptyButton } from 'styling/buttons';
import { Helmet } from 'react-helmet';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useSelectProfileData from 'hooks/useSelectProfileData';

const baseUrl = process.env.REACT_APP_BASE_URL;

type Props = {
  eventInfo: GroupEvent;
  timeZoneString: string;
  disableStyling?: boolean;
};

// Note: This component relies on the 'addevent' third party <script> being loaded
const AddGroupEventToCalendarButton = ({
  eventInfo,
  timeZoneString,
}: Props) => {
  const { partner } = useSelectProfileData();

  const { url: callUrl } = useGetJoinCall({ callInfo: eventInfo });

  const calendarButtonRef = useRef<HTMLDivElement>(null);

  const addToCalendarParam = getUrlParam('add_to_calendar') as string;
  const [shouldOpenCalendar, setShouldOpenCalendar] = useState(
    !!addToCalendarParam
  );
  // Fixes a bug requiring a reload of the <script>
  useEffect(() => {
    // @ts-ignore
    setTimeout(() => window.addeventatc?.refresh(), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldOpenCalendar) {
      (async () => {
        // 2 second delay needed to load calendar script
        await new Promise((resolve) => setTimeout(resolve, 2000));
        calendarButtonRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        calendarButtonRef?.current?.click();
        setShouldOpenCalendar(false);
      })();
    }
  }, [shouldOpenCalendar, setShouldOpenCalendar, calendarButtonRef]);

  if (
    !eventInfo.volunteer ||
    !eventInfo.start_time ||
    !eventInfo.code ||
    !eventInfo.duration ||
    !timeZoneString
  )
    return null;

  const detailsLink = `${
    partner && partner?.subdomain
      ? `https://${partner?.subdomain}.${baseUrl?.replace('https://', '')}`
      : baseUrl
  }group-event-details?code=${eventInfo.code}`;

  const returnPartnerName = () => {
    if (partner && partner?.name) return partner?.name;

    return 'Digital Boost';
  };

  const calendarEventText = `${returnPartnerName()} ${toTitleCase(
    eventInfo?.title?.toLowerCase()
  )} ${toTitleCase(eventInfo.type)} hosted by ${
    eventInfo.volunteer.first_name
  } ${eventInfo.volunteer.last_initial}.\n\nTo join the ${toTitleCase(
    eventInfo.type
  )} visit ${
    !!callUrl ? callUrl : detailsLink
  } \n\nFor more details about this ${eventInfo.type}, visit\n${detailsLink}`;

  return (
    <div className="addToCalendarWrapper">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.addevent.com/libs/atc/themes/fff-theme-6/theme.css"
          type="text/css"
          media="all"
        />
        <script
          type="text/javascript"
          src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
        ></script>
      </Helmet>
      <div
        title="Add to calendar"
        className="addeventatc"
        data-styling="none"
        ref={calendarButtonRef}
        onClick={() => {
          setShouldOpenCalendar(!shouldOpenCalendar);
        }}
      >
        <DashboardEmptyButton
          variant="text"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            paddingLeft: '2px',
          }}
        >
          <DateRangeIcon sx={{ marginRight: '5px' }} />
          <div>Add to calendar</div>
        </DashboardEmptyButton>

        <span className="start atc-groupevent-hidden-text">
          {displayTime(
            eventInfo.start_time,
            timeZoneString,
            `${defaultDateFormat} ${defaultTimeFormat}`
          )}
        </span>
        <span className="end atc-groupevent-hidden-text">
          {getMomentWithTimezone(eventInfo.start_time, timeZoneString)
            .add(eventInfo.duration, 'minutes')
            .format(`${defaultDateFormat} ${defaultTimeFormat}`)}
        </span>
        <span className="timezone atc-groupevent-hidden-text">
          {timeZoneString}
        </span>
        <span className="title atc-groupevent-hidden-text">{`${returnPartnerName()} ${toTitleCase(
          eventInfo?.title?.toLowerCase()
        )} ${toTitleCase(eventInfo.type)}`}</span>
        <span className="description atc-groupevent-hidden-text">
          {calendarEventText}
        </span>
        <span className="location atc-groupevent-hidden-text">
          {!!callUrl ? callUrl : eventInfo.call_url}
        </span>
      </div>
    </div>
  );
};

export default AddGroupEventToCalendarButton;
