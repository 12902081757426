import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { SocialShareModal } from '../../components/SocialShareButton';
import { getUrlParam, removeUrlParam } from '../../utils/url';
import { toTitleCase } from '../../utils/text';
import {
  formatDate,
  timeZoneAbbreviation,
  displayTime,
  getMomentWithTimezone,
} from '../../utils/time';
import { defaultDateFormat, defaultTimeFormat } from '../../constants';
import api from '../../api';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import useGetGroupEvents from '../../apiHooks/groupEvents/useGetGroupEvents';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import './group-event-registered.scss';
import useGetSpeakerBriefing from 'apiHooks/groupEvents/useGetSpeakerBriefing';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import { DateRangeOutlined } from '@mui/icons-material';
import {
  selectGroupEventMasterclassName,
  selectGroupEventWebinarName,
  selectGroupEventWorkshopName,
} from 'features/reduxStore/groupEventsName';
import { useSelector } from 'react-redux';

const baseUrl = process.env.REACT_APP_BASE_URL;
const currentURL = window.location.href;
const extractedURL =
  currentURL.indexOf('org.uk/') !== -1
    ? currentURL.substring(0, currentURL.indexOf('org.uk/') + 7)
    : baseUrl;
const GroupEventRegistered = () => {
  const groupEventCode = getUrlParam('code') as string;

  const { groupEventData: groupEvent, hasFinishedLoadingGroupEvents } =
    useGetGroupEvents({ code: groupEventCode });

  const { speakerBriefingData, hasFinishedLoadingSpeakerBriefing } =
    useGetSpeakerBriefing({ code: groupEventCode });

  const {
    duration,
    interest,
    title,
    start_time = '',
    type: eventType = '',
    volunteer: volunteerProfile,
    id,
  } = groupEvent;

  const {
    help_needed,
    interest_expertise,
    id: speakerBriefingId,
  } = speakerBriefingData[0] || {};

  let metaTitle = 'Digital Boost';
  if (eventType && interest) {
    metaTitle = toTitleCase(interest?.name) + ' ' + toTitleCase(eventType);
  }

  const { userLocation, organisationProfile } = useSelectProfileData();
  const partnerWhiteLabel = organisationProfile?.partnerInfo?.white_label
    ? organisationProfile.partnerInfo.name
    : null;
  const { timezone } = userLocation || {};
  const timezoneToUse = timezone || moment.tz.guess(true);
  const formattedDate = formatDate(start_time, timezoneToUse, duration || 60);
  const [knowOption, setKnowOption] = useState<string | undefined>(undefined);
  const [question, setQuestion] = useState('');
  const [socialShareModalVisible, setSocialShareModalVisible] = useState(false);
  const [questionSuccess, setQuestionSuccess] = useState(false);
  const navigate = useNavigate();
  const shareLink = removeUrlParam(
    removeUrlParam(
      window.location.href.replace('registered', 'details'),
      'register'
    ),
    'join_call'
  );

  // Fixes a bug requiring a reload of the <script>
  useEffect(() => {
    // @ts-ignore
    setTimeout(() => window.addeventatc?.refresh(), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (interest_expertise) {
      setKnowOption(interest_expertise);
    }
    if (help_needed) {
      setQuestion(help_needed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFinishedLoadingSpeakerBriefing]);

  const sendQuestion = async () => {
    try {
      if (interest_expertise || help_needed) {
        await api.updateQuestionGroupEvent({
          groupEventId: id,
          orgQuestion: question,
          interestExpertise: knowOption,
          speakerBriefingId,
        });
      } else {
        await api.registerQuestionGroupEvent({
          groupEventId: id,
          orgQuestion: question,
          interestExpertise: knowOption,
        });
      }
      setQuestionSuccess(true);
    } catch (err) {
      console.log('err: ', err);
    }
  };
  const masterclassName =
    useSelector(selectGroupEventMasterclassName) || 'Masterclass';
  const webinarName = useSelector(selectGroupEventWebinarName) || 'Webinar';
  const workshopName = useSelector(selectGroupEventWorkshopName) || 'Workshop';
  const nameEventType =
    eventType === 'masterclass'
      ? masterclassName
      : eventType === 'webinar'
      ? webinarName
      : workshopName;

  return hasFinishedLoadingGroupEvents && hasFinishedLoadingSpeakerBriefing ? (
    <>
      <Helmet>
        <title>{metaTitle} | Register for free</title>
        <meta
          name="description"
          content={`Register to attend our free online ${metaTitle}`}
        ></meta>
      </Helmet>
      <Grid className="group-event-registered__container">
        <h1>You are registered for the {toTitleCase(nameEventType)}!</h1>
        <div className="accepted-img">
          <img
            src="/group-conversation.png"
            alt="workshop registered"
            height="200px"
          />
        </div>
        <h3>
          {title || interest?.name}
          <br />
          {formattedDate?.date?.day}-{formattedDate?.date?.month}{' '}
          {formattedDate?.date?.year}, {formattedDate?.time?.hour}{' '}
          {timeZoneAbbreviation(formattedDate?.time?.timeZone as string)}
        </h3>
        <p>
          We have sent you a confirmation email and have added it to your
          dashboard.
          <br />
          Make sure to add it to your calendar and feel free to share it with
          your network.
        </p>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap="30px"
        >
          {/* <Box className="group-event-registered__link-button">
            <img src="/question-blue.png" alt="question icon" />
            <p>Ask Question</p>
          </Box> */}

          <div style={{ position: 'relative' }}>
            <Box
              className="group-event-registered__link-button"
              onClick={() =>
                setSocialShareModalVisible(!socialShareModalVisible)
              }
            >
              <ShareOutlinedIcon />
              <p>Share</p>
            </Box>
            {socialShareModalVisible && (
              <SocialShareModal
                eventType={eventType}
                link={shareLink}
                subject={interest?.name}
                name={`${volunteerProfile.first_name} ${
                  volunteerProfile.last_name ? volunteerProfile.last_name : ''
                }`}
                companyName={volunteerProfile.employer}
                startTime={groupEvent.start_time}
                onCloseClick={() => setSocialShareModalVisible(false)}
                className="group-event-registered__social-share-modal"
              />
            )}
          </div>

          <StyledCalendarButton
            eventInfo={groupEvent}
            partnerWhiteLabel={partnerWhiteLabel}
            timeZoneString={timezone || ''}
          />
        </Grid>

        <Grid className="group-event-registered__question-container">
          {!questionSuccess ? (
            <>
              <h6>Help us make the session relevant for you.</h6>
              <div className="label">
                How much do you know about {interest?.name}?
              </div>

              <Grid
                container
                gap="16px"
                className="group-event-registered__know-container"
              >
                <div
                  className={`option-container ${
                    knowOption === 'nothing_at_all'
                      ? 'option-container-selected'
                      : ''
                  }`}
                  onClick={() => setKnowOption('nothing_at_all')}
                >
                  Nothing at all
                </div>
                <div
                  className={`option-container ${
                    knowOption === 'i_know_some'
                      ? 'option-container-selected'
                      : ''
                  }`}
                  onClick={() => setKnowOption('i_know_some')}
                >
                  I know some
                </div>
                <div
                  className={`option-container ${
                    knowOption === 'i_know_a_lot'
                      ? 'option-container-selected'
                      : ''
                  }`}
                  onClick={() => setKnowOption('i_know_a_lot')}
                >
                  I know a lot, just need help with some specifics
                </div>
              </Grid>

              <div className="label">{`Your Question(s)`}</div>
              <textarea
                placeholder="What is the main question you'd like to address in the session?"
                rows={5}
                value={question}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setQuestion(e.target.value);
                }}
              />
              <Grid
                container
                direction="row"
                gap="8px"
                className="buttons-container"
              >
                <DashboardFilledButton
                  sx={{ width: '130px' }}
                  variant="contained"
                  disabled={question.length < 1 || knowOption === undefined}
                  onClick={sendQuestion}
                >
                  Send
                </DashboardFilledButton>
                {question.length > 0 && (
                  <DashboardEmptyButton
                    sx={{ width: '130px' }}
                    variant="outlined"
                    onClick={() => setQuestion('')}
                  >
                    Clear
                  </DashboardEmptyButton>
                )}
              </Grid>
            </>
          ) : (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              className="success-container"
            >
              <img src="/check_success.svg" alt="Success icon" />
              <p>Your question has been sent to the organiser</p>
            </Grid>
          )}
        </Grid>

        <Box className="group-event-registered__link-button center-button">
          <DashboardFilledButton
            sx={{ width: '170px' }}
            variant="contained"
            onClick={() => navigate('/dashboard/actions')}
          >
            Go to Dashboard
          </DashboardFilledButton>
        </Box>
      </Grid>
    </>
  ) : (
    <div className="group-event-registered__loading-container">
      <img
        className="group-event-registered__loading-spinner"
        src="/loading.gif"
        alt="loading"
      />
    </div>
  );
};

const StyledCalendarButton = ({
  eventInfo,
  timeZoneString,
  partnerWhiteLabel,
}: any) => {
  const { url: callUrl } = useGetJoinCall({ callInfo: eventInfo });
  const returnPartnerName = () => {
    if (partnerWhiteLabel) return partnerWhiteLabel;

    return 'Digital Boost';
  };

  const detailsLink = `${extractedURL}group-event-details?code=${eventInfo.code}`;

  const calendarEventText = `${returnPartnerName()} ${toTitleCase(
    eventInfo?.title?.toLowerCase()
  )} ${toTitleCase(eventInfo.type)} hosted by ${
    eventInfo.volunteer.first_name
  } ${eventInfo.volunteer.last_initial}.\n\nTo join the ${toTitleCase(
    eventInfo.type
  )} visit ${
    !!callUrl ? callUrl : detailsLink
  } \n\nFor more details about this ${eventInfo.type}, visit\n${detailsLink}`;

  return (
    <Box className="addeventatc group-event-registered__link-button">
      <span className="start atc-groupevent-hidden-text">
        {displayTime(
          eventInfo.start_time,
          timeZoneString,
          `${defaultDateFormat} ${defaultTimeFormat}`
        )}
      </span>
      <span className="end atc-groupevent-hidden-text">
        {getMomentWithTimezone(eventInfo.start_time, timeZoneString)
          .add(eventInfo.duration, 'minutes')
          .format(`${defaultDateFormat} ${defaultTimeFormat}`)}
      </span>
      <span className="timezone atc-groupevent-hidden-text">
        {timeZoneString}
      </span>
      <span className="title atc-groupevent-hidden-text">{`${returnPartnerName()} ${toTitleCase(
        eventInfo?.title?.toLowerCase()
      )} ${toTitleCase(eventInfo.type)}`}</span>
      <span className="description atc-groupevent-hidden-text">
        {calendarEventText}
      </span>
      <span className="location atc-groupevent-hidden-text">
        {!!callUrl ? callUrl : eventInfo.call_url}
      </span>
      <DateRangeOutlined />
      <p>Add to Calendar</p>
    </Box>
  );
};

export default GroupEventRegistered;
