import React, { useEffect, useState } from 'react';
import apiInstance from '../../api/config';
import qs from 'qs';
import GroupEventScoringGrid from './GroupEventScoringGrid';
import './nps-score.scss';
import Button from '../../components/Button';
import TextAreadWithLabel from '../../components/TextAreaWithLabel';
import useWindowSize from '../../hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { getErrorMessageFromResponse } from '../../utils/error';
import { CarouselProvider } from 'pure-react-carousel';
import { Checkbox, Switch } from '@mui/material';
import ToolTip from 'components/ToolTip';
import { selectGroupEventMasterclassName } from 'features/reduxStore/groupEventsName';
import { useSelector } from 'react-redux';

interface Props {
  eventIdFromUser?: number | undefined;
  setDisableAfterFeedbackGiven?: any | undefined;
}

const GroupEventScore = ({ setDisableAfterFeedbackGiven }: Props) => {
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 750;

  const [feedBackAlreadyGiven, setFeedbackAlreadyGiven] =
    useState<boolean>(false);
  const eventCode = qs.parse(
    window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
  ).code;
  const [eventGroupId, setEventGroupId] = useState<any>(null);
  const [score, setScore] = useState<number | null>(null);
  const [feedback, setFeedback] = useState<string>('');
  const [hideName, setHideName] = useState<boolean>(false);
  const [reasons, setReasons] = useState<any>();
  const [userReasons, setUserReasons] = useState<number[]>([]);
  const [thankYou, setThankYou] = useState<any>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [canShareFeedback, setCanShareFeedback] = useState<boolean>(true);
  const navigate = useNavigate();

  // optional parameters allow overriding at point of calling
  const sendFeedback = async () => {
    let shareFeedback = false;
    if (score != null && score >= 7) {
      shareFeedback = canShareFeedback;
    }

    const postData = {
      review: feedback,
      group_event: eventGroupId,
      score: score,
      hide_reviewer_details: hideName,
      reasons: userReasons,
      can_share_feedback: shareFeedback,
    };
    apiInstance
      .post(`api/netpromoterscore/groupeventscores/`, postData)
      .then((response) => {
        if (response) {
          if (setDisableAfterFeedbackGiven) {
            setDisableAfterFeedbackGiven(true);
          }

          return true;
        }
      })
      .catch((err) => {
        setErrorMessage(getErrorMessageFromResponse(err));
        return false;
      });
  };

  const getReasons = async () => {
    const reasonsResponse = (
      await apiInstance.get(`api/netpromoterscore/groupeventreason/?limit=100`)
    ).data.results;
    setReasons(reasonsResponse);
  };
  const getGroupEventID = async () => {
    const groupEventId = (
      await apiInstance.get(`api/groupevent/groupevent/${eventCode}`)
    ).data.id;
    setEventGroupId(groupEventId);
    return new Promise((resolve) => {
      resolve(groupEventId);
    });
  };

  const getFeedbackGiven = async (id: any) => {
    const isFeedbackGiven = (
      await apiInstance.get(
        `api/netpromoterscore/groupeventscores/check/?group_event_id=${id}`
      )
    ).data;
    setFeedbackAlreadyGiven(isFeedbackGiven.is_score_sent);
  };

  const addRemoveReason = (id: number) => {
    const newReasons = [...userReasons];
    if (newReasons.includes(id)) {
      newReasons.forEach((reason, i) => {
        if (reason === id) {
          newReasons.splice(i, 1);
        }
      });
    } else {
      newReasons.push(id);
    }
    setUserReasons(newReasons);
  };

  function onScoreSet(score: number) {
    setScore(score);
  }

  // Handles initial data fetching & handles URL params
  useEffect(() => {
    (async () => {
      try {
        await getGroupEventID().then(async (response) => {
          if (response) {
            await getFeedbackGiven(response);
            await getReasons();
          }
        });
      } catch (err) {
        setErrorMessage(
          'We are sorry, the page is not available at the moment.'
        );
        return false;
      }
    })();
  });
  const masterclassName =
    useSelector(selectGroupEventMasterclassName) || 'Masterclass';
  if (thankYou) {
    return (
      <div className="nps-score__thankyou-container">
        <div className="nps_score__thankyou">
          <h2>Thank you for your feedback</h2>
          <p>
            {typeof score === 'number' && score < 7
              ? 'Please be aware that mentors who receive poor reviews from several mentees are restricted from using our service so that your peers will not experience similar issues in the future.'
              : 'We will share it with the volunteer so that they know how it went and how they can improve.'}
          </p>
          {typeof score === 'number' && score >= 7 && (
            <div className="nps-score__thankyou-social-buttons-buttons-container">
              <p className="nps-score__thankyou-social-text">
                Help us spread the word!
              </p>
            </div>
          )}
        </div>
      </div>
    );
  } else if (feedBackAlreadyGiven) {
    return (
      <div className="nps-score__feedbackalreadygiven-container">
        <div className="nps_score__feedbackalreadygiven">
          <h2>Feedback already given</h2>
          <p>You already gave a feedback for this {masterclassName} session.</p>
          <div className="nps-score__feedbackalreadygiven-buttons-container">
            <Button
              type={'secondary'}
              text={'Back to Dashboard'}
              onClickFunction={() => {
                navigate('/dashboard/actions');
              }}
            />
          </div>
        </div>
      </div>
    );
  } else if (reasons) {
    return (
      <div className="nps-score">
        <div className="nps-score__container">
          <h2>
            On a scale of 0 to 10, how likely are you to recommend this
            {masterclassName} to others?
          </h2>

          <div className="nps-score__mobile-card nps-score__grid-container">
            <GroupEventScoringGrid
              score={score}
              onScoreSet={onScoreSet}
              mobile={!!isMobile}
            />
            <div className="nps-score__grid-descriptor-text">
              <span>{`${isMobile ? 'Less likely' : 'Not likely at all'}`}</span>{' '}
              <span></span>
              {`${isMobile ? 'More likely' : 'Very likely'}`}
            </div>
          </div>

          <div className="nps-score__details-container nps-score__mobile-card">
            {typeof score === 'number' && score < 7 && (
              <>
                <div className="info-text">
                  We’re sorry to hear that your {masterclassName} session was
                  unsatisfatory.
                </div>
                <h3>Why was your {masterclassName} unsatisfactory?</h3>
                {reasons
                  .filter((r: any) => r.score_group === 'detractor')
                  .map((reason: any) => (
                    <div key={`reason-${reason.id}`} className="checkbox">
                      <label>
                        <Checkbox
                          checked={userReasons.includes(reason.id)}
                          onChange={() => {
                            addRemoveReason(reason.id);
                          }}
                          name={reason.description}
                          color="primary"
                        />
                        <span>{reason.description}</span>
                      </label>
                    </div>
                  ))}
              </>
            )}
            {typeof score === 'number' && score >= 7 && (
              <>
                <div className="info-text">
                  We’re pleased to hear that it went well!
                </div>
                <h3>What went well?</h3>
                {reasons
                  .filter((r: any) => r.score_group === 'promoter')
                  .map((reason: any) => (
                    <div key={`reason-${reason.id}`} className="checkbox">
                      <label>
                        <Checkbox
                          checked={userReasons.includes(reason.id)}
                          onChange={() => {
                            addRemoveReason(reason.id);
                          }}
                          name={reason.description}
                          color="primary"
                        />
                        <span>{reason.description}</span>
                      </label>
                    </div>
                  ))}
              </>
            )}
          </div>
          <div className="nps-score__mobile-card nps-score__feedback-container">
            <TextAreadWithLabel
              onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setFeedback(e.target.value);
              }}
              rows={3}
              cols={4}
              placeHolder={
                'If you want to share more details, please leave them here'
              }
              label={
                'Your feedback is highly valued by your mentor and you can choose to share your feedback with them anonymously so that your privacy is protected.'
              }
            />
            <div>
              <Switch
                checked={hideName}
                onChange={() => setHideName(!hideName)}
              />
              Share anonymously
            </div>

            {typeof score === 'number' && score >= 7 && (
              <div>
                <Switch
                  checked={canShareFeedback}
                  onChange={() => {
                    setCanShareFeedback(!canShareFeedback);
                  }}
                />
                I’m happy for Digital Boost to share my feedback in marketing
                materials
                <ToolTip
                  text={`We love to highlight the amazing mentoring that happens here at Digital Boost! So from time to time, we celebrate our businesses and mentors on our social channels. Please let us know if you're happy for us to share your feedback, it will be shared anonymously if you choose to hide your name and details. If you choose to allow us to share your feedback, you might be tagged in Digital Boost posts.`}
                  id="nps-tagged"
                  className="nps-tagged-tooltip"
                />
              </div>
            )}
          </div>

          <div className="nps-score__button-container">
            <Button
              className="nps-score__button"
              type={'secondary'}
              text={'Cancel'}
              onClickFunction={() => {
                navigate('/dashboard/actions');
              }}
            />
            <Button
              className="nps-score__button"
              type={'primary'}
              disabled={!(typeof score === 'number')}
              text={'Send'}
              onClickFunction={() => {
                sendFeedback().then(() => {
                  window.scrollTo(0, 0);
                  setThankYou(true);
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="nps-score__thankyou-container">
        <div className="nps_score__thankyou">
          <h1 className="nps-score__error nps-score__title-container">
            {' '}
            {errorMessage}
          </h1>
        </div>
      </div>
    );
  }
};

const WrappedInProvider = (props: Props) => (
  <CarouselProvider
    totalSlides={11}
    naturalSlideWidth={1}
    naturalSlideHeight={1}
    visibleSlides={1}
    currentSlide={5} // this variable determines the default slide only see https://github.com/express-labs/pure-react-carousel
    className="scoring-grid-mobile"
  >
    <GroupEventScore {...props} />
  </CarouselProvider>
);

export default WrappedInProvider;
