import React, { useState, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import SelectButton from '../SelectButton';
import Button from '../Button';
import TimeSlotTag from '../TimeSlotTag';
import Tag from '../Tag';
import ToolTip from '../ToolTip';
import { getAvailability, timeZoneAbbreviation } from '../../utils/time';
import { toTitleCase } from '../../utils/text';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import useWindowSize from '../../hooks/useWindowSize';
import apiInstance from '../../api/config';

import { TimeSlot } from '../../types';
import './mentor-card.scss';
import { ViewDetailsButton } from './style';
import { DashboardFilledButton } from 'styling/buttons';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';
import { Mentor } from '../../../types';

type Props = {
  mentor: Mentor;
  proposedTimeSlot?: TimeSlot;
  topPriority?: string;
  topPriorityId?: number;
  acceptProposedTimeslot?: () => void;
  declineProposedTimeslot?: () => void;
  isSendingProposedTimeslotResponse?: boolean;
  sentResponse?: 'accepted' | 'declined';
  modalOpen: boolean;
  closeModal: () => void;
  onSelect?: () => void;
  selected?: boolean;
  disabledSelect?: boolean;
  hideSelect?: boolean;
  allowDirectRequest?: boolean;
  allowRebook?: boolean;
  keyword?: string;
  customModalStyles?: any;
  recommendedId?: number;
  isSelected?: boolean;
};

interface BioAndExperienceProps extends Props {
  bioExpanded: boolean;
  mentor: Mentor;
  experiencesExpanded: boolean;
  setBioExpanded: (arg: boolean) => void;
  setExperiencesExpanded: (arg: boolean) => void;
  directRequestLink?: string;
  rebookLink?: string;
  isMobile?: boolean;
  isSelected?: boolean;
}

type MentorModalWrapperButtonProps = {
  mentor: Mentor;
  className?: string;
  children: React.ReactNode;
  recommendedId?: number;
  volunteerClicked?: number;
  refetchRecommendedData?: any;
  topPriorityId?: number | undefined;
  isSelected?: boolean;
};

const daysOfWeek: { [arg: number]: string } = {
  0: 'Mon',
  1: 'Tue',
  2: 'Wed',
  3: 'Thu',
  4: 'Fri',
  5: 'Sat',
  6: 'Sun',
};

const BioAndExperience = ({
  mentor,
  closeModal,
  hideSelect,
  selected,
  disabledSelect,
  onSelect,
  directRequestLink,
  rebookLink,
  isSelected,
}: BioAndExperienceProps) => {
  const {
    first_name,
    last_initial,
    current_role,
    employer,
    summary,
    languages,
    interests,
    disabilities,
    disability_info,
  } = mentor;

  const [displaySummary, setDisplaySummary] = React.useState(
    summary !== null && summary.length > 150 ? summary.slice(0, 150) : summary
  );
  const session_title = useSelector(selectSessionTitle);
  const updateDisplaySummary = () => setDisplaySummary(summary);
  const navigate = useNavigate();
  return (
    <Stack
      display="flex"
      direction="column"
      justifyContent="space-between"
      className="bio"
      overflow="scroll"
    >
      <Box
        sx={{
          position: 'absolute',
          top: 4,
          right: 4,
          display: { xs: 'none', sm: 'none', md: 'block' },
        }}
      >
        <IoIosClose size={35} onClick={closeModal} />
      </Box>
      <Box>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <h4>
            {first_name} {last_initial}
          </h4>
          <h5>
            {current_role} {employer ? '| ' + employer : ''}
          </h5>
        </Box>
        {summary && (
          <div className="about-me-container">
            <div className="categories-title">About Me</div>
            <div className="modal-about-me-text">
              {displaySummary !== null &&
              displaySummary.length < summary?.length
                ? displaySummary + ' ...'
                : summary}
            </div>
            {displaySummary && displaySummary.length < summary?.length ? (
              <ViewDetailsButton
                variant="text"
                className="read-more read-more-summary"
                onClick={() => updateDisplaySummary()}
                sx={{ alignSelf: 'flex-end' }}
              >
                View more
              </ViewDetailsButton>
            ) : null}
          </div>
        )}
        <div className="categories-title">Skills</div>
        <p>{interests.join(', ')}</p>

        {languages.length > 0 ? (
          <>
            <div className="categories-title">Languages</div>
            <p>{languages.join(', ')}</p>
          </>
        ) : null}
        {disabilities || disability_info ? (
          <div>
            <div className="categories-title">Mentor Disability</div>
            {disabilities
              ? disabilities.map((disability: { id: number; name: string }) => (
                  <div className="mentee-modal-text" key={disability.id}>
                    {disability.name}
                  </div>
                ))
              : null}
            <div className="mentee-modal-text">{disability_info}</div>
          </div>
        ) : null}
      </Box>
      <Box className="button-wrapper">
        {!hideSelect && (
          <SelectButton
            onClick={onSelect}
            selected={selected}
            disabled={disabledSelect}
          />
        )}
        {directRequestLink && !isSelected && (
          <Link
            to={directRequestLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="primary" text={`Request a ${session_title}`} />
          </Link>
        )}
        {rebookLink && !isSelected && (
          <DashboardFilledButton
            onClick={() => navigate(rebookLink)}
            sx={{ width: '180px', marginBottom: '10px' }}
            variant="contained"
          >
            Request a session
          </DashboardFilledButton>
        )}
      </Box>
    </Stack>
  );
};

const MentorModal = ({
  mentor,
  proposedTimeSlot,
  acceptProposedTimeslot,
  declineProposedTimeslot,
  isSendingProposedTimeslotResponse,
  sentResponse,
  topPriority,
  topPriorityId,
  modalOpen,
  closeModal,
  selected,
  onSelect,
  disabledSelect,
  hideSelect,
  allowDirectRequest,
  allowRebook,
  keyword,
  recommendedId,
  isSelected,
}: Props) => {
  const {
    id,
    first_name,
    last_initial,
    current_role,
    employer,
    availability,
    profile_picture,
    timezone: volunteerTimezone,
  } = mentor;
  const { isMobile } = useWindowSize();

  const rebookLink = allowRebook
    ? `/volunteer-details?volunteer[]=${id}${
        keyword && keyword.length > 0 && `&keyword=${keyword}`
      }`
    : undefined;

  let directRequestLink =
    allowDirectRequest && recommendedId
      ? `/book-session?volunteer=${id}&ia=${id}&recommended=${recommendedId}`
      : allowDirectRequest
      ? `/book-session?volunteer=${id}&ia=${id}`
      : undefined;

  // if there is a top priority then pass it through in the URL.
  if (allowDirectRequest && topPriorityId) {
    directRequestLink += `&interest[]=${topPriorityId}`;
  }

  const { userLocation } = useSelectProfileData();
  const { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } =
    userLocation || {};
  const { days: availableDays } = availability;
  const { availabilityStartTimeLocal, availabilityEndTimeLocal } =
    getAvailability(
      { availabilityObj: availability },
      volunteerTimezone,
      timezone
    );
  const [bioExpanded, setBioExpanded] = useState<boolean>(false);
  const [experiencesExpanded, setExperiencesExpanded] =
    useState<boolean>(false);

  const userTimezone = timeZoneAbbreviation(timezone);
  const session_title = useSelector(selectSessionTitle);
  return (
    <Modal open={modalOpen} onClose={closeModal} className="modal-container">
      <Box className="mentor-modal">
        <Grid xs={12} sm={12} md={4} lg={4} className="profile-container">
          <Box
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              display: { xs: 'block', sm: 'block', md: 'none' },
            }}
          >
            <IoIosClose size={35} onClick={closeModal} />
          </Box>
          <Stack
            direction={{ xs: 'row', sm: 'row', md: 'column', lg: 'column' }}
          >
            <div
              className="profile-picture"
              style={{
                backgroundImage: profile_picture
                  ? `url(${profile_picture})`
                  : 'url(/avatar.png)',
              }}
            ></div>

            {proposedTimeSlot ? (
              <BioAndExperience
                mentor={mentor}
                bioExpanded={bioExpanded}
                experiencesExpanded={experiencesExpanded}
                closeModal={closeModal}
                setBioExpanded={setBioExpanded}
                setExperiencesExpanded={setExperiencesExpanded}
                hideSelect={hideSelect}
                selected={selected}
                disabledSelect={disabledSelect}
                onSelect={onSelect}
                modalOpen={modalOpen}
                directRequestLink={directRequestLink}
                rebookLink={rebookLink}
                isMobile={isMobile}
                isSelected={isSelected}
              />
            ) : (
              <Grid
                direction="column"
                className="mentor-modal__availability-container"
              >
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                  <h4>
                    {first_name} {last_initial}
                  </h4>
                  <h5>
                    {current_role} {employer ? '| ' + employer : ''}
                  </h5>
                </Box>
                <Grid
                  display="flex"
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <h6>Availability</h6>
                  <ToolTip
                    text={`For the best chance of having your request accepted, choose times within this window.`}
                    id="mentor-modal"
                    className="mentor-modal__hours-tooltip"
                  />
                </Grid>
                <Grid
                  display="flex"
                  direction="row"
                  flexWrap="wrap"
                  gap="8px"
                  className="mentor-modal__days-container"
                >
                  {Object.entries(daysOfWeek).map(([i, day], index) => {
                    return (
                      <Fragment key={`${id}-day-${index}`}>
                        <Tag
                          text={day}
                          type={
                            !!availableDays.includes(Number(i))
                              ? 'secondary'
                              : 'tertiary'
                          }
                          className={`mentor-modal__day-tag`}
                        />
                      </Fragment>
                    );
                  })}
                </Grid>
                <h6>Time</h6>
                <Grid className="mentor-modal__hours-container">
                  <Grid
                    display="flex"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {availabilityStartTimeLocal} to {availabilityEndTimeLocal}{' '}
                    {userTimezone}
                    <ToolTip
                      text={`The time shown here is in your time zone but the expert may be in a different time zone`}
                      id="mentor-modal"
                      className="mentor-modal__hours-tooltip"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Stack>
        </Grid>
        <Grid xs={12} sm={12} md={8} lg={8} className="info-section">
          {proposedTimeSlot && sentResponse && timezone ? (
            <div className="mentor-modal__proposed-timeslot-container">
              <h2>Proposed Time {toTitleCase(sentResponse)}</h2>
              <p>
                Thank you. You have {sentResponse} this volunteer's proposed
                alternative time for a {session_title}
              </p>
            </div>
          ) : proposedTimeSlot && timezone ? (
            <div className="mentor-modal__proposed-timeslot-container">
              <Box
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  display: { xs: 'none', sm: 'none', md: 'block' },
                }}
              >
                <IoIosClose size={35} onClick={closeModal} />
              </Box>
              <h2>Alternative Time Proposed</h2>
              <span>
                You requested a call{topPriority ? ` on ${topPriority}` : ''}.
              </span>
              <span>
                {mentor.first_name} would like to help you, but they have
                proposed a different time because they cannot make the time you
                requested.
              </span>
              <TimeSlotTag
                time={proposedTimeSlot.start_time}
                timeZone={timezone}
                className="mentor-modal__proposed-timeslot-tag"
              />
              <div className="mentor-modal__proposed-timeslot-buttons-container">
                <Button
                  type="primary"
                  text={'Accept Proposed Time'}
                  onClickFunction={acceptProposedTimeslot}
                  loading={isSendingProposedTimeslotResponse}
                />
                <Button
                  type="secondary"
                  text={'Decline Proposed Time'}
                  onClickFunction={declineProposedTimeslot}
                />
              </div>
              {proposedTimeSlot.showAlertAnotherAlreadyAccepted ? (
                <div className="mentor-modal__alert">
                  You already have a call with a different volunteer scheduled
                  from this request - if you accept this proposed time, both
                  calls will be scheduled.
                </div>
              ) : null}
            </div>
          ) : (
            <>
              <BioAndExperience
                mentor={mentor}
                bioExpanded={bioExpanded}
                experiencesExpanded={experiencesExpanded}
                closeModal={closeModal}
                setBioExpanded={setBioExpanded}
                setExperiencesExpanded={setExperiencesExpanded}
                hideSelect={hideSelect}
                selected={selected}
                disabledSelect={disabledSelect}
                onSelect={onSelect}
                modalOpen={modalOpen}
                directRequestLink={directRequestLink}
                rebookLink={rebookLink}
                isSelected={isSelected}
              />
            </>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export const MentorModalWrapperButton = ({
  mentor,
  className,
  children,
  recommendedId,
  volunteerClicked,
  refetchRecommendedData,
  topPriorityId,
  isSelected,
}: MentorModalWrapperButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClickButton = async () => {
    if (recommendedId && refetchRecommendedData) {
      await apiInstance.patch(
        `api/recommendation/recommendedvolunteers/${recommendedId}/`,
        {
          clicked_times: volunteerClicked! + 1,
        }
      );
    }

    setModalOpen(!modalOpen);
  };

  return (
    <>
      <button
        className={`mentor-modal__wrapper-button ${className ? className : ''}`}
        onClick={() => mentor?.first_name && handleClickButton()}
      >
        {children}
      </button>
      {modalOpen && (
        <MentorModal
          mentor={mentor}
          topPriorityId={topPriorityId}
          modalOpen={modalOpen}
          isSelected={isSelected}
          closeModal={() => {
            setModalOpen(false);
            refetchRecommendedData();
          }}
          allowDirectRequest
          hideSelect
          recommendedId={recommendedId}
        />
      )}
    </>
  );
};

export default MentorModal;
