import React, { useEffect, useState, useContext, useCallback } from 'react';
import apiInstance from '../../api/config';
import qs from 'qs';
import ScoringGrid from './ScoringGrid';
import './nps-score.scss';
import TextAreadWithLabel from '../../components/TextAreaWithLabel';
import useWindowSize from '../../hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { CarouselProvider, CarouselContext } from 'pure-react-carousel';
import { SocialShareModal } from '../../components/SocialShareButton';
import { getErrorMessageFromResponse } from '../../utils/error';
import Switch from '@mui/material/Switch';
import ToolTip from 'components/ToolTip';
import { Checkbox } from '@mui/material';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import TrustBox from 'components/TrustBox';

interface Props {
  eventIdFromUser?: number | undefined;
  setDisableAfterFeedbackGiven?: any | undefined;
}

type SendFeedbackProps = {
  scoreParam?: number;
  userReasonsParam?: number[];
  resetNoShow?: boolean;
  resetEventDidNotHappen?: boolean;
  resetCanShare?: boolean;
};

const NpsScore = ({ eventIdFromUser, setDisableAfterFeedbackGiven }: Props) => {
  const carouselContext = useContext(CarouselContext);
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 750;

  const { setStoreState: setCarouselStoreState } = carouselContext;
  const [currentSlide, setCurrentSlide] = useState<number>(
    carouselContext.state.currentSlide
  );
  useEffect(() => {
    const onChange = () => {
      setCurrentSlide(carouselContext.state.currentSlide);
    };
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const [feedBackAlreadyGiven, setFeedbackAlreadyGiven] =
    useState<boolean>(false);
  const [boostCallEvents, setBoostCallEvents] = useState<any>(false);
  const [score, setScore] = useState<number | null>(null);
  const [feedback, setFeedback] = useState<string>('');
  const [hideName, setHideName] = useState<boolean>(false);
  const [noShow, setNoShow] = useState<boolean>(false);
  const [eventDidNotHappen, setEventDidNotHappen] = useState<boolean>(false);
  const [reasons, setReasons] = useState<any>([]);
  const [userReasons, setUserReasons] = useState<number[]>([]);
  const [thankYou, setThankYou] = useState<any>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [prevSlide, setPrevSlide] = useState<number | null>(null);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [canShareFeedback, setCanShareFeedback] = useState<boolean>(true);

  const navigate = useNavigate();
  const getFeedbackType = (score: number) => {
    if (score > -1 && score < 7) {
      setHideName(true);
      return 'detractor';
    } else if (score < 9) {
      setHideName(false);
      return 'neutral';
    } else {
      setHideName(false);
      return 'promoter';
    }
  };

  // optional parameters allow overriding at point of calling
  const sendFeedback = useCallback(
    async ({
      scoreParam,
      userReasonsParam,
      resetNoShow,
      resetEventDidNotHappen,
    }: SendFeedbackProps = {}) => {
      if (!boostCallEvents) return;
      let shareFeedback = false;

      if (score != null && score >= 7) {
        shareFeedback = canShareFeedback;
      }

      const postData = {
        review: feedback,
        event_id: boostCallEvents.event_id,
        score:
          typeof scoreParam === 'number'
            ? scoreParam
            : eventDidNotHappen && noShow
            ? 0
            : score,
        hide_reviewer_details: hideName,
        organisation: boostCallEvents.organisation.id,
        volunteer: boostCallEvents.volunteer.id,
        no_show: resetNoShow ? false : noShow,
        event_did_not_happen: resetEventDidNotHappen
          ? false
          : eventDidNotHappen,
        score_type: 'organisation_scoring_volunteer',
        organisation_scoring_volunteer: true,
        boostcall_event: boostCallEvents.id,
        reasons: userReasonsParam || userReasons,
        can_share_feedback: shareFeedback,
      };
      apiInstance
        .post(`api/netpromoterscore/boostcallscores/`, postData)
        .then((response) => {
          if (response) {
            if (setDisableAfterFeedbackGiven) {
              setDisableAfterFeedbackGiven(true);
            }

            return true;
          }
        })
        .catch((err) => {
          setErrorMessage(getErrorMessageFromResponse(err));
          return false;
        });
    },
    [
      boostCallEvents,
      feedback,
      userReasons,
      score,
      noShow,
      hideName,
      setDisableAfterFeedbackGiven,
      eventDidNotHappen,
      canShareFeedback,
    ]
  );

  const getReasons = async () => {
    const reasonsResponse = (
      await apiInstance.get(`api/netpromoterscore/reason/?limit=100`)
    ).data.results;
    reasonsResponse.sort((a: any, b: any) => a.id - b.id);
    setReasons(reasonsResponse.filter((r: any) => !!r.show_to_organisation));
  };

  const getFeedbackGiven = async (
    eventIdFromUser?: number,
    setDisableAfterFeedbackGiven?: any | undefined
  ) => {
    let boostCallEventId;
    if (eventIdFromUser) {
      boostCallEventId = eventIdFromUser;
    } else {
      boostCallEventId = qs.parse(
        window.location.search.slice(
          window.location.search.lastIndexOf('?') + 1
        )
      ).id;
    }

    const isFeedbackGiven = (
      await apiInstance.get(
        `api/netpromoterscore/boostcallscores/score/?boostcall_event_id=${boostCallEventId}`
      )
    ).data.is_score_sent;
    setFeedbackAlreadyGiven(isFeedbackGiven);
  };

  const getDataFromId = async (eventIdFromUser?: number) => {
    let boostCallEventId;
    if (eventIdFromUser) {
      boostCallEventId = eventIdFromUser;
    } else {
      boostCallEventId = qs.parse(
        window.location.search.slice(
          window.location.search.lastIndexOf('?') + 1
        )
      ).id;
    }
    try {
      const boostCallResponse = (
        await apiInstance.get(`api/event/boostcallevents/${boostCallEventId}/`)
      ).data;
      setBoostCallEvents(boostCallResponse);
      return boostCallResponse;
    } catch (err) {
      setErrorMessage(getErrorMessageFromResponse(err));
    }
  };

  const onScoreSet = useCallback(
    (scoreValue: number) => {
      if (!boostCallEvents) return;
      setScore(scoreValue);
    },
    [boostCallEvents]
  );

  // Handles initial data fetching & handles URL params
  useEffect(() => {
    (async () => {
      try {
        await getFeedbackGiven(eventIdFromUser);
        // Fetch call data
        await getDataFromId(eventIdFromUser);

        // Handle URL param: ?score=
        let qsScore = qs.parse(
          window.location.search.slice(
            window.location.search.lastIndexOf('?') + 1
          )
        ).score;
        if (qsScore && typeof qsScore === 'string') {
          setScore(parseInt(qsScore));
          setCarouselStoreState({ currentSlide: parseInt(qsScore) });
        }
        // Fetch reasons
        await getReasons();
      } catch (err) {
        setErrorMessage(getErrorMessageFromResponse(err));
        return false;
      }
    })();
  }, [eventIdFromUser, setCarouselStoreState]);

  // Handles setting score any time currentSlide is changed, including based on ?score= url param
  useEffect(() => {
    if (prevSlide !== currentSlide) {
      onScoreSet(currentSlide);
      if (
        prevSlide &&
        getFeedbackType(currentSlide) !== getFeedbackType(prevSlide)
      ) {
        setUserReasons([]);
        setNoShow(false);
        setEventDidNotHappen(false);
      }
      setPrevSlide(currentSlide);
    }

    if (currentSlide > -1 && currentSlide < 7) {
      setHideName(true);
    } else {
      setHideName(false);
    }
  }, [currentSlide, onScoreSet, prevSlide]);

  useEffect(() => {
    if (eventDidNotHappen) {
      setHideName(true);
    }
  }, [eventDidNotHappen]);

  const checkIsNoShowReason = (reason: any) =>
    reason && reason.no_show && reason.show_to_organisation;
  const addRemoveReason = (id: number) => {
    const newReasons = [...userReasons];
    if (newReasons.includes(id)) {
      newReasons.forEach((reason, i) => {
        if (reason === id) {
          newReasons.splice(i, 1);
        }
      });
    } else {
      newReasons.push(id);
    }

    setUserReasons(newReasons);
  };

  // Handles updating reasons based on no_show url param AFTER reasons has been fetched
  useEffect(() => {
    if (!reasons || !boostCallEvents) return;

    // Handle URL param: ?no_show=
    let noShow = qs.parse(
      window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
    ).no_show;
    if (noShow && typeof noShow === 'string') {
      setScore(null);
      setEventDidNotHappen(true);
      setCarouselStoreState({ currentSlide: 0 });
    }
    // eslint-disable-next-line
  }, [boostCallEvents, reasons]);

  if (thankYou) {
    return (
      <div className="nps-score__thankyou-container">
        <div className="nps_score__thankyou">
          {errorMessage && (
            <h1 className="nps-score__error nps-score__title-container">
              {' '}
              {errorMessage}
            </h1>
          )}
          <h2>Thank you so much for your feedback</h2>
          <p>
            {typeof score === 'number' && score < 7
              ? 'Please be aware that mentors who receive poor reviews from several mentees are restricted from using our service so that your peers will not experience similar issues in the future.'
              : ''}
          </p>
          <div
            className={`nps-score__thankyou-buttons-container ${
              typeof score === 'number' && score >= 9 && 'nps-score__margin-top'
            }`}
          >
            {typeof score === 'number' && score > 8 && (
              <DashboardFilledButton
                variant="contained"
                sx={{ width: '250px' }}
                onClick={() => {
                  navigate('/mentor-select');
                }}
              >
                Request another session
              </DashboardFilledButton>
            )}
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '250px' }}
              onClick={() => {
                navigate('/dashboard/actions');
              }}
            >
              Back to Dashboard
            </DashboardEmptyButton>
          </div>
          {typeof score === 'number' && score >= 9 && (
            <div className="nps-score__thankyou-social-buttons-buttons-container">
              <p className="nps-score__thankyou-social-text">
                Enjoying our free mentoring? Help us support more founders by
                sharing your experience on Trustpilot 👇
              </p>

              <TrustBox />
            </div>
          )}

          {shareModalOpen && (
            <SocialShareModal
              link="www.digitalboost.org.uk"
              eventType={boostCallEvents.title}
              subject={boostCallEvents.title}
              name={`${boostCallEvents?.volunteer?.first_name} ${
                boostCallEvents?.volunteer?.last_name
                  ? boostCallEvents?.volunteer?.last_name
                  : ''
              }`}
              companyName={boostCallEvents?.volunteer?.employer}
              startTime={boostCallEvents.start_time}
              isNPS
              className={'nps-score__social-share-modal'}
              onCloseClick={() => setShareModalOpen(false)}
            />
          )}
        </div>
      </div>
    );
  } else if (feedBackAlreadyGiven) {
    return (
      <div className="nps-score__feedbackalreadygiven-container">
        <div className="nps_score__feedbackalreadygiven">
          {errorMessage && (
            <h1 className="nps-score__error nps-score__title-container">
              {' '}
              {errorMessage}
            </h1>
          )}
          <h2>Feedback already given</h2>
          <p>You already gave a feedback for this mentoring session.</p>
          <div className="nps-score__feedbackalreadygiven-buttons-container">
            {typeof score === 'number' && score > 8 && (
              <DashboardFilledButton
                variant="contained"
                sx={{ width: '200px' }}
                onClick={() => {
                  navigate('/mentor-select');
                }}
              >
                Request another session
              </DashboardFilledButton>
            )}
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '200px' }}
              onClick={() => {
                navigate('/dashboard/actions');
              }}
            >
              Back to Dashboard
            </DashboardEmptyButton>
          </div>
        </div>
      </div>
    );
  } else if (boostCallEvents) {
    return (
      <div className="nps-score">
        <div className="nps-score__container">
          <div className="nps-score__mobile-card nps-score__title-container">
            {errorMessage && (
              <h1 className="nps-score__error"> {errorMessage}</h1>
            )}
            <h1 className="nps-score__title">
              How likely are you to recommend{' '}
              {boostCallEvents.volunteer.first_name} as a mentor?
            </h1>
          </div>
          <div className="nps-score__call_did_not_happen">
            <div className="checkbox">
              <label>
                <Checkbox
                  checked={eventDidNotHappen}
                  onChange={() => {
                    setEventDidNotHappen(!eventDidNotHappen);
                    if (!eventDidNotHappen) {
                      setScore(null);
                      setUserReasons([]);
                      setNoShow(false);
                    }
                  }}
                  name="Call didn't happen"
                  color="primary"
                />

                <span>Call didn't happen</span>
              </label>
            </div>
          </div>
          {!eventDidNotHappen && (
            <div className="nps-score__mobile-card nps-score__grid-container">
              <ScoringGrid
                score={score}
                onScoreSet={onScoreSet}
                setCarouselStoreState={setCarouselStoreState}
                mobile={!!isMobile}
                currentSlide={currentSlide}
              />
              <div className="nps-score__grid-descriptor-text">
                <span>{`${
                  isMobile ? 'Less likely' : 'Not likely at all'
                }`}</span>{' '}
                <span></span>
                {`${isMobile ? 'More likely' : 'Very likely'}`}
              </div>
            </div>
          )}

          {typeof score === 'number' && !eventDidNotHappen && (
            <>
              <div className="nps-score__details-container nps-score__mobile-card">
                {typeof score === 'number' && score < 7 && (
                  <>
                    <div className="info-text">
                      We’re sorry to hear that your call with{' '}
                      {boostCallEvents.volunteer.first_name} wasn’t a success.
                    </div>

                    <h3>Please let us know what happened.</h3>

                    {reasons
                      .filter((r: any) => r.score_group === 'detractor')
                      .map((reason: any) => {
                        const isNowShowReason = checkIsNoShowReason(reason);
                        return (
                          <div key={`reason-${reason.id}`} className="checkbox">
                            <label>
                              <Checkbox
                                checked={userReasons.includes(reason.id)}
                                onChange={() => {
                                  addRemoveReason(reason.id);
                                  if (!!isNowShowReason) setNoShow(!noShow);
                                }}
                                name={reason.description}
                                color="primary"
                              />

                              <span>{reason.description}</span>
                            </label>
                          </div>
                        );
                      })}
                  </>
                )}
                {score >= 7 && (
                  <>
                    {score > 8 && (
                      <div className="info-text">
                        We’re so happy your call was a success!
                      </div>
                    )}

                    {score > 8 ? (
                      <div>
                        <h3>
                          Please let us know what made your mentor amazing here
                          👇
                        </h3>
                      </div>
                    ) : (
                      <div>
                        <h3>Thank you for your feedback!</h3>
                        <h3>
                          Is there anything that your mentor could do
                          differently to improve their sessions in the future?
                        </h3>
                      </div>
                    )}

                    {score > 8 && (
                      <div className="nps-score__details-container nps-score__mobile-card">
                        {reasons
                          .filter((r: any) => r.score_group === 'promoter')
                          .map((reason: any) => {
                            return (
                              <div key={reason.id} className="checkbox">
                                <label>
                                  <Checkbox
                                    checked={userReasons.includes(reason.id)}
                                    onChange={() => addRemoveReason(reason.id)}
                                    name={reason.description}
                                    color="primary"
                                  />
                                  <span>{reason.description}</span>
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="nps-score__mobile-card nps-score__feedback-container">
                {score >= 7 && score <= 8 ? (
                  <TextAreadWithLabel
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFeedback(e.target.value);
                    }}
                    rows={3}
                    cols={4}
                    placeHolder={
                      'If you’re happy to share additional feedback with us, please share it here '
                    }
                  />
                ) : score > 8 ? (
                  <TextAreadWithLabel
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFeedback(e.target.value);
                    }}
                    rows={3}
                    cols={4}
                    placeHolder={
                      'Our mentors love to hear about your experiences and hear pointers on how they can improve their sessions in the future.                      '
                    }
                    label={
                      'As a thank you to your volunteer, we encourage you to leave a note here that can be shared on their Dashboard!'
                    }
                  />
                ) : (
                  <TextAreadWithLabel
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFeedback(e.target.value);
                    }}
                    rows={3}
                    cols={4}
                    placeHolder={
                      'If you’re happy to share additional feedback with us, please share it here '
                    }
                    label={
                      'All negative feedback is anonymous to protect your privacy and help you to feel safe sharing this with us.'
                    }
                  />
                )}

                <div>
                  <Switch
                    checked={hideName}
                    onChange={() => setHideName(!hideName)}
                  />
                  Share anonymously
                </div>

                {score >= 7 && (
                  <div>
                    <Switch
                      checked={canShareFeedback}
                      onChange={() => {
                        setCanShareFeedback(!canShareFeedback);
                      }}
                    />
                    I’m happy for Digital Boost to share my feedback in
                    marketing materials
                    <ToolTip
                      text={`We love to highlight the amazing mentoring that happens here at Digital Boost! So from time to time, we celebrate our businesses and mentors on our social channels. Please let us know if you're happy for us to share your feedback, it will be shared anonymously if you choose to hide your name and details. If you choose to allow us to share your feedback, you might be tagged in Digital Boost posts.`}
                      id="nps-tagged"
                      className="nps-tagged-tooltip"
                    />
                  </div>
                )}
              </div>
            </>
          )}

          {eventDidNotHappen && (
            <div className="nps-score__details-container nps-score__mobile-card">
              <div className="info-text">
                We’re sorry to hear that your call with{' '}
                {boostCallEvents.volunteer.first_name} didn't happen.
              </div>

              <h3>
                Please take a few minutes to let us know why it didn’t happen.
              </h3>

              {reasons
                .filter((r: any) => r.event_did_not_happen === true)
                .map((reason: any) => {
                  const isNowShowReason = checkIsNoShowReason(reason);
                  return (
                    <div key={reason.id} className="checkbox">
                      <label>
                        <Checkbox
                          checked={userReasons.includes(reason.id)}
                          onChange={() => {
                            addRemoveReason(reason.id);
                            if (!!isNowShowReason) setNoShow(!noShow);
                          }}
                          name={reason.description}
                          color="primary"
                        />

                        <span>{reason.description}</span>
                      </label>
                    </div>
                  );
                })}
              <div className="nps-score__mobile-card nps-score__feedback-container">
                <TextAreadWithLabel
                  onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setFeedback(e.target.value);
                  }}
                  rows={3}
                  cols={4}
                  placeHolder={
                    'If you’re happy to share additional feedback with us, please share it here                    '
                  }
                  label={
                    'All negative feedback is anonymous to protect your privacy and help you to feel safe sharing this with us.                    '
                  }
                />

                <div>
                  <Switch
                    checked={hideName}
                    onChange={() => setHideName(!hideName)}
                  />
                  Share anonymously
                </div>
              </div>
            </div>
          )}

          <div className="nps-score__button-container">
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '250px' }}
              onClick={() => {
                navigate('/dashboard/actions');
              }}
            >
              Cancel
            </DashboardEmptyButton>
            <DashboardFilledButton
              variant="contained"
              sx={{ width: '250px' }}
              disabled={!(typeof score === 'number') && !eventDidNotHappen}
              onClick={() => {
                sendFeedback().then(() => {
                  window.scrollTo(0, 0);
                  setThankYou(true);
                });
              }}
            >
              Send
            </DashboardFilledButton>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="nps-score__loading-container">
        {errorMessage && (
          <h1 className="nps-score__error nps-score__title-container">
            {' '}
            {errorMessage}
          </h1>
        )}
        <img
          className="nps_score__loading-spinner"
          src="/loading.gif"
          alt="loading"
        />
      </div>
    );
  }
};

const WrappedInProvider = (props: Props) => (
  <CarouselProvider
    totalSlides={11}
    naturalSlideWidth={1}
    naturalSlideHeight={1}
    visibleSlides={1}
    currentSlide={5} // this variable determines the default slide only see https://github.com/express-labs/pure-react-carousel
    className="scoring-grid-mobile"
  >
    <NpsScore {...props} />
  </CarouselProvider>
);

export default WrappedInProvider;
